
    import Vue from "vue"
    import Component from "vue-class-component";
    import { Watch } from "vue-property-decorator"
    import ApiButton from "@/components/ApiButton.vue";
    import apiClient from "@/utilities/ApiClient";
    import * as toastr from "toastr";

    @Component({ components: { ApiButton } })
    export default class Residents extends Vue {
      
        //
        // -- properties
        //

        headers: Array<any> = [];
        rows: Array<any> = [];

        // 
        // -- watchers
        //
        
        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            this.getResidents();
        }

        @Watch("$store.state.includeInactive", { immediate: true, deep: true })
        private onIncludeInactiveChanged() {
            this.getResidents();
        }
        
        //
        // -- methods
        //
        
        async getResidents() {
            const serverData = await apiClient.get(`api/residents/residents?agreementNumber=${this.$store.state.agreementNumber}&incInactive=${this.$store.state.includeInactive}`);
            this.headers = [];
            this.rows = [];
            this.headers.push(...serverData.headers);
            this.rows.push(...serverData.rows);
            const table = this.$el.querySelector(".table-responsive")
            if (!table) return;
            table.scrollLeft = table.scrollWidth;
        }

        async saveResidents(event?: Event) {
            const request = { headers: this.headers, rows: this.rows };
            const response: { success: boolean } = await apiClient.post("api/residents/saveResidents", request, event);
            if (!response.success) {
                toastr.error("Failed to save resident numbers");
                this.getResidents();
            }
        }

    }
